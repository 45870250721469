<template>
    <div class="section-wrapper soil-test-wrapper">
        <breadcumb v-if="$route.name !== 'tea_garden_service.dashboard'"/>
        <b-overlay :show="loading">
        <div class="form-wrapper">
            <b-row>
                <b-col>
                    <b-card :title="$t('teaGardenDashboard.online_tea_license_dashboard')">
                        <b-card-text>
                        <b-row>
                            <!-- check box all  -->
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-checkbox v-model="check_all"  class="d-inline-block ml-1"> {{$t('teaGardenDashboard.check_all')}}
                            </b-form-checkbox>
                        </b-col>
                        <!-- fiscal year list  -->
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <b-form-group
                            label-for="fiscal_year_id"
                            >
                            <template v-slot:label>
                                {{ $t('globalTrans.fiscal_year') }}
                            </template>
                            <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            </b-form-group>
                        </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="4" v-for="(item, index) in serviceList" :key="index">
                                <b-form-checkbox :id="`service_id_tea_license_${index}`" v-model="search.service_ids" :name="`checkbox-${index}`" :value="item.value"
                                    unchecked-value="not_accepted">
                                    {{ item.text }}
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                            <b-row class="mt-3 text-right">
                                <b-col>
                                    <b-button @click="searchData" class="btn btn-primary">{{ $t('globalTrans.search') }}</b-button>
                                </b-col>
                            </b-row>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <!-- for new -->
                    <div class="info-card-wrapper">
                        <b-card>
                            <b-card-text>
                                <!-- for new -->
                                <b-row>
                                    <b-col class="bdr" sm="3">
                                        <router-link class="text-white" :to="{name: 'tea_garden_service.approval-process.applicant_list', query:{service_ids: search.service_ids , not_status_ids: [1, 8], limit: 100, application_type: 1, fiscal_year_id: this.search.fiscal_year_id} }">
                                        <div class="info-card">
                                            <i class="ri-file-copy-2-fill"></i>
                                            <div class="content">
                                                <p>{{ $t('teaGardenConfig.total_application') }} ({{ $t('globalTrans.new') }})</p>
                                                <h3>{{ $n(totalInfo.totalApplication) }}</h3>
                                            </div>
                                        </div>
                                        </router-link>
                                    </b-col>
                                    <b-col class="bdr" sm="3">
                                        <router-link class="text-white" :to="{name: 'tea_garden_service.approval-process.applicant_list', query:{service_ids: search.service_ids , not_status_ids: [1,6,7,8], limit: 100, application_type: 1, fiscal_year_id: this.search.fiscal_year_id} }">
                                        <div class="info-card">
                                            <i class="ri-file-copy-2-fill"></i>
                                            <div class="content">
                                                <p>{{ $t('teaGardenConfig.pending_application') }} ({{ $t('globalTrans.new') }})</p>
                                                <h3>{{ $n(totalInfo.totalPendingApplication) }}</h3>
                                            </div>
                                        </div>
                                        </router-link>
                                    </b-col>
                                    <b-col class="bdr" sm="3">
                                        <router-link class="text-white" :to="{name: 'tea_garden_service.approval-process.approved_list', query:{service_ids: search.service_ids , status: 6, limit: 100, application_type: 1, fiscal_year_id: this.search.fiscal_year_id} }">
                                        <div class="info-card">
                                            <i class="ri-file-copy-2-fill"></i>
                                            <div class="content">
                                                <p>{{ $t('teaGardenConfig.publish_application') }} ({{ $t('globalTrans.new') }})</p>
                                                <h3>{{ $n(totalInfo.totalPublishedApplication) }}</h3>
                                            </div>
                                        </div>
                                        </router-link>
                                    </b-col>
                                    <b-col class="bdr" sm="3">
                                        <router-link class="text-white" :to="{name: 'tea_garden_service.approval-process.rejected_list', query:{service_ids: search.service_ids , status: 7, limit: 100, application_type: 1, fiscal_year_id: this.search.fiscal_year_id} }">
                                        <div class="info-card">
                                            <i class="ri-file-copy-2-fill"></i>
                                            <div class="content">
                                                <p>{{ $t('globalTrans.rejected') }} {{ $t('globalTrans.application') }} ({{ $t('globalTrans.new') }})</p>
                                                <h3>{{ $n(totalInfo.totalRejectedApplication) }}</h3>
                                            </div>
                                        </div>
                                        </router-link>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-card>
                    </div>
                    <!-- for renew -->
                    <div class="info-card-wrapper">
                        <b-card>
                            <b-card-text>
                                <b-row>
                                    <b-col class="bdr" sm="3">
                                        <router-link class="text-white" :to="{name: 'tea_garden_service.approval-process.applicant_list', query:{service_ids: search.service_ids , not_status_ids: [1, 8], limit: 100, application_type: 2, fiscal_year_id: this.search.fiscal_year_id} }">
                                        <div class="info-card">
                                            <i class="ri-file-copy-2-fill"></i>
                                            <div class="content">
                                                <p>{{ $t('teaGardenConfig.total_application') }} ({{ $t('globalTrans.renew') }})</p>
                                                <h3>{{ $n(totalInfo.totalApplicationRenew) }}</h3>
                                            </div>
                                        </div>
                                        </router-link>
                                    </b-col>
                                    <b-col class="bdr" sm="3">
                                        <router-link class="text-white" :to="{name: 'tea_garden_service.approval-process.applicant_list', query:{service_ids: search.service_ids,not_status_ids: [1,6,7,8], limit: 100, application_type: 2, fiscal_year_id: this.search.fiscal_year_id} }">
                                        <div class="info-card">
                                            <i class="ri-file-copy-2-fill"></i>
                                            <div class="content">
                                                <p>{{ $t('teaGardenConfig.pending_application') }} ({{ $t('globalTrans.renew') }})</p>
                                                <h3>{{ $n(totalInfo.totalPendingApplicationRenew) }}</h3>
                                            </div>
                                        </div>
                                        </router-link>
                                    </b-col>
                                    <b-col class="bdr" sm="3">
                                        <router-link class="text-white" :to="{name: 'tea_garden_service.approval-process.approved_list', query:{service_ids: search.service_ids , status: 6, limit: 100, application_type: 2, fiscal_year_id: this.search.fiscal_year_id} }">
                                        <div class="info-card">
                                            <i class="ri-file-copy-2-fill"></i>
                                            <div class="content">
                                                <p>{{ $t('teaGardenConfig.publish_application') }} ({{ $t('globalTrans.renew') }})</p>
                                                <h3>{{ $n(totalInfo.totalPublishedApplicationRenew) }}</h3>
                                            </div>
                                        </div>
                                        </router-link>
                                    </b-col>
                                    <b-col class="bdr" sm="3">
                                        <router-link class="text-white" :to="{name: 'tea_garden_service.approval-process.rejected_list', query:{service_ids: search.service_ids, status: 7, limit: 100, application_type: 2, fiscal_year_id: this.search.fiscal_year_id} }">
                                        <div class="info-card">
                                            <i class="ri-file-copy-2-fill"></i>
                                            <div class="content">
                                                <p>{{ $t('globalTrans.rejected') }} {{ $t('globalTrans.application') }} ({{ $t('globalTrans.renew') }})</p>
                                                <h3>{{ $n(totalInfo.totalRejectedApplicationRenew) }}</h3>
                                            </div>
                                        </div>
                                        </router-link>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-card>
                    </div>
                    <b-card-text>
                        <div id="chart" class="px-3">
                            <apexchart type="bar" height="450" :options="chartOptions" :series="series"></apexchart>
                            <div class="tea-chart-number">
                                <p><i>{{ $t('globalTrans.approved_application_number') }}</i></p>
                            </div>
                            <p class="tea-chart-year"><i>{{ $t('globalTrans.fiscal_year') }} ({{ fiscalYearName(this.search.fiscal_year_id) }})</i></p>
                        </div>
                    </b-card-text>
                </b-col>
            </b-row>
        </div>
        </b-overlay>
    </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
  mixins: [teaGardenService],
  components: {},
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      errors: [],
      check_all: false,
      search: {
        service_ids: [],
        fiscal_year_id: this.$store.state.currentFiscalYearId
      },
      totalInfo: {
        totalApplication: 0,
        totalPendingApplication: 0,
        totalRejectedApplication: 0,
        totalPublishedApplication: 0,
        totalApplicationRenew: 0,
        totalPendingApplicationRenew: 0,
        totalRejectedApplicationRenew: 0,
        totalPublishedApplicationRenew: 0
      },
      newSeriesData: [],
      renewSeriesData: [],
      categories: [],
      categoriesBn: []
    }
  },
  created () {
    this.check_all = true
    setTimeout(() => {
        this.loadData()
    }, 2000)
  },
  computed: {
    currentLocale () {
        return this.$i18n.locale
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    serviceList: function () {
      return this.$store.state.TeaGardenService.commonObj.masterServiceList.filter(item => JSON.parse(item.service_type).includes(1) && [30, 31, 32, 33, 34].includes(item.value))
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    series () {
        return [{
            name: `${this.$t('globalTrans.approved')} ${this.$t('globalTrans.application')} (${this.$t('globalTrans.new')})`,
            data: this.newSeriesData
        }, {
            name: `${this.$t('globalTrans.approved')} ${this.$t('globalTrans.application')} (${this.$t('globalTrans.renew')})`,
            data: this.renewSeriesData
        }]
    },
    chartOptions () {
        return {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '30%',
                    endingShape: 'rounded'
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: this.currentLocale === 'en' ? this.categories : this.categoriesBn
            },
            fill: {
                opacity: 1
            },
            colors: ['#77B6EA', '#B90E03', '#034092', '#F9EB17', '#172FF9', '#17F939', '#7D0EDA', '#755D58', '#2F2C61', '#9F4E40', '#9F3E40', '#304E40']
        }
    }
  },
  methods: {
    searchData () {
      this.loadData()
    },
    async loadData () {
        // check service_ids[] can't empty
        if (this.search.service_ids.length === 0) {
            return
        }
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        const result = await RestApi.getData(teaGardenServiceBaseUrl, 'dashboard/one-tea-licnese-dashboard', this.search)
        if (result.success) {
            this.totalInfo = result.data.totalInfo
            const chartInfo = result.data.chartInfo.map(item => {
                const service = this.serviceList.find(obj => obj.value === parseInt(item.service_id))
                if (service) {
                        const customItem = {
                        service_name_en: service.text_en,
                        service_name_bn: service.text_bn,
                        sorting_order: service.sorting_order
                    }
                    return Object.assign({}, item, customItem)
                } else {
                    const customItem = {
                        service_name_en: '',
                        service_name_bn: '',
                        sorting_order: ''
                    }
                    return Object.assign({}, item, customItem)
                }
            }).sort((a, b) => a.sorting_order - b.sorting_order)
            const categoryArr = []
            const categoryBnArr = []
            const newSeriesArr = []
            const renewSeriesArr = []
            chartInfo.forEach(item => {
                categoryArr.push(item.service_name_en)
                categoryBnArr.push(item.service_name_bn)
                newSeriesArr.push(item.totalNewApplication)
                renewSeriesArr.push(item.totalRenewApplication)
            })
            this.categories = categoryArr
            this.categoriesBn = categoryBnArr
            this.newSeriesData = newSeriesArr
            this.renewSeriesData = renewSeriesArr
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false })
    },
    serviceNameEn (id) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => (item.value === id))
        return obj.text_en ? obj.text_en : ''
    },
    serviceNameBn (id) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => (item.value === id))
        return obj.text_bn ? obj.text_bn : ''
    },
    fiscalYearName (id) {
        const obj = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => (item.value === id))
        if (obj) {
            return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
        }
    }
  },
  watch: {
    check_all: function (newValue) {
      const arr = []
      if (newValue) {
        this.serviceList.forEach(item => {
            arr.push(item.value)
        })
      }
      this.search.service_ids = arr
    }
  }
}
</script>
<style>
/* custom chart design  */
.tea-chart-number {
    position: absolute;
    left: 0;
    top: 45%;
    transform: translate(-77px, 600%);
}
.tea-chart-number p {
    transform: rotate(-90deg);
    font-weight: 600;
}
.font-bn .tea-chart-number {
    transform: translate(-30%, 600%);
}
.tea-chart-year {
    text-align: center;
    margin: -12px 0 10px;
    font-weight: 600;
}
/* custom chart design  end*/
.soil-test-wrapper .card .card-body .card-title {
    background: var(--primary) !important;
}

.soil-test-wrapper .info-card-wrapper .card {
    color: #fff;
    background: var(--alt);
    border: none;
}

.soil-test-wrapper .info-card-wrapper .info-card {
    text-align: center;
}

.soil-test-wrapper .info-card-wrapper .info-card i {
    font-size: 25px;
}

.soil-test-wrapper .info-card-wrapper .row .bdr {
    border-right: 1px solid #ddd;
}

.soil-test-wrapper .info-card-wrapper .row .bdr:last-child {
    border: none;
}

.soil-test-wrapper .card .card-body h2 {
    color: #fff;
    text-transform: uppercase;
}
</style>
